var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"other_user_streams_layout"},[_c('v-row',{staticClass:"mt-13 mb-16 top-text",attrs:{"no-gutters":"","align":"baseline"}},[_c('v-col',{staticClass:"ml-2 back-icon",attrs:{"cols":"1"}},[_c('router-link',{staticClass:"linked",attrs:{"to":{
                    name: 'otherUser.profile.viewSlug',
                    params: { slug: _vm.otherUser.slug },
                }}},[_c('v-icon',{attrs:{"size":"33"}},[_vm._v("mdi-chevron-left")])],1)],1),_c('v-col',{staticClass:"ml-5"},[_vm._v(" "+_vm._s(_vm.otherUser.name + _vm.$t("pages.profile.stream.header"))+" ")])],1),_c('div',{staticStyle:{"display":"flex","flex-direction":"column","justify-content":"center"}},[(!!_vm.otherUser.stream && !_vm.hasStreamTicket)?_c('div',{staticClass:"px-8 pb-8"},[_c('div',{staticClass:"text-center text-h4"},[_vm._v(" "+_vm._s(_vm.$t("pages.profile.stream.title"))+" ")]),_c('div',{staticClass:"text-center mt-2 mb-10"},[_vm._v(" "+_vm._s(_vm.formatDate( _vm.convertUTCDatetimeToLocal( _vm.otherUser.stream.starts_at ) ))+" ")]),_c('div',{staticClass:"other_user_streams_layout__unlock-heart"},[_c('icon-heart-lock'),_c('div',{staticClass:"other_user_streams_layout__unlock-price"},[_vm._v(" "+_vm._s(_vm.otherUser.stream.price)+_vm._s(_vm.currency)+" ")])],1),_c('div',{staticClass:"other_user_streams_layout__unlock-button"},[_c('gradient-button',{staticClass:"mt-4 enable-events",on:{"click":function($event){return _vm.purchaseTicket()}}},[_vm._v(_vm._s(_vm.$t("pages.profile.stream.purchaseTicket")))])],1),_c('div',{staticClass:"text-center grey--text"},[_vm._v(" "+_vm._s(_vm.otherUser.stream.stream_tickets.length)+"/"+_vm._s(_vm.otherUser.stream.viewers_limit)+" ")])]):_vm._e(),(!!_vm.otherUser.stream && _vm.hasStreamTicket)?_c('div',{staticClass:"px-8 pb-8"},[_c('div',{staticClass:"text-center text-h4"},[_vm._v(" "+_vm._s(_vm.$t("pages.profile.stream.title"))+" ")]),_c('div',{staticClass:"text-center mt-2 mb-10"},[_vm._v(" "+_vm._s(_vm.formatDate( _vm.convertUTCDatetimeToLocal( _vm.otherUser.stream.starts_at ) ))+" ")]),_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('gradient-button',{staticClass:"mx-4 mb-4 button-story-unlock enable-events",class:!_vm.isAvailableToGoLive
                            ? 'gradient-button--disabled'
                            : '',on:{"click":function($event){return _vm.handleStartWatching()}}},[_vm._v(" "+_vm._s(_vm.$t("pages.profile.stream.startWatching"))+" ")])],1),_c('div',{staticClass:"text-center grey--text"},[_vm._v(" "+_vm._s(_vm.otherUser.stream.stream_tickets.length)+"/"+_vm._s(_vm.otherUser.stream.viewers_limit)+" ")])]):_vm._e()]),_c('div',{staticClass:"grey--text caption text-center"},[_vm._v(" "+_vm._s(_vm.$t("pages.user.setting.creator.streams.rules.title"))),_c('a',{attrs:{"href":"/stream-rules","target":"_blank"}},[_vm._v(_vm._s(_vm.$t("pages.user.setting.creator.streams.rules.name")))]),_vm._v(". ")]),(_vm.otherUser && _vm.otherUser.streams)?[_c('hr',{staticClass:"mx-6 my-16",staticStyle:{"opacity":"0.25"}}),_c('div',{staticClass:"text-center text-h4"},[_vm._v(" "+_vm._s(_vm.$t("pages.profile.streams.rating.title"))+" ")]),_c('div',{staticClass:"rating mt-6",class:_vm.averageStreamerRating < 50
                    ? 'rating--red'
                    : _vm.averageStreamerRating < 70
                    ? 'rating--orange'
                    : _vm.averageStreamerRating < 90
                    ? 'rating--yellow'
                    : 'rating--green'},[_c('svg',[_c('circle',{attrs:{"cx":"105","cy":"105","r":"90"}}),_c('circle',{style:(("--percent: " + _vm.averageStreamerRating)),attrs:{"cx":"105","cy":"105","r":"90"}})]),_c('div',{staticClass:"title text-h2"},[_c('div',[_vm._v(" "+_vm._s(_vm.averageStreamerRating < 50 ? "😞" : _vm.averageStreamerRating < 70 ? "😐" : _vm.averageStreamerRating < 90 ? "😃" : "😍")+" ")])])]),_c('div',{staticClass:"text-center justify-center mt-4 pb-16",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"mr-4"},[_c('div',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.otherUser.streams.length))]),_c('div',{staticStyle:{"opacity":"0.5"}},[_vm._v(" "+_vm._s(_vm.$t("pages.profile.streams.rating.stats.streams"))+" ")])]),_c('div',{staticClass:"ml-4"},[_c('div',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.averageStreamsDuration)+" "+_vm._s(_vm.$t("pages.profile.streams.rating.stats.min"))+" ")]),_c('div',{staticStyle:{"opacity":"0.5"}},[_vm._v(" "+_vm._s(_vm.$t( "pages.profile.streams.rating.stats.averageDuration" ))+" ")])])])]:_vm._e(),(_vm.showInsufficientFundsDialog)?_c('InsufficientFundsDialog',{on:{"close":function($event){_vm.showInsufficientFundsDialog = false}}}):_vm._e(),(_vm.showStreamNotStartedYetDialog)?_c('stream-not-started-yet',{attrs:{"date":_vm.formatDate(
                _vm.convertUTCDatetimeToLocal(_vm.otherUser.stream.starts_at)
            ),"difference":_vm.streamStartsAtDifferenceMessage},on:{"close":function($event){_vm.showStreamNotStartedYetDialog = false}}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }